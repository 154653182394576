<template>
  <div>
    <!-- <div class="vx-col w-full sm:m-0 "> -->
    <div slot="no-body" class="full-page-bg-color bg-white">
      <div class="vx-row no-gutter justify-center items-center">
        <div class="vx-col  sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
          <div class="lg:ml-24 lg:mt-24 px-8 pt-8 login-tabs-container">
            <vx-card v-show="done == false">
              <div class="vx-card__title mb-4 pt-4">
                <h4 class="mb-4 font-bold text-md">
                  {{ $t("ChangePassword") }}
                </h4>
              </div>
              <vs-input
                class="w-full mb-base"
                label-placeholder="Old Password"
                v-model="Model.old_password"
              />
              <vs-input
                class="w-full mb-base"
                label-placeholder="New Password"
                v-model="Model.new_password"
              />
              <vs-input
                class="w-full mb-base"
                label-placeholder="Confirm Password"
                v-model="ConfirmPassword"
              />

              <!-- Save & Reset Button -->
              <div class="flex flex-wrap items-center justify-end">
                <vs-button @click="ChangePassword" class="ml-auto mt-2">{{
                  $t("SaveChanges")
                }}</vs-button>
                <vs-button
                  @click="cancle"
                  class="ml-4 mt-2"
                  type="border"
                  color="warning"
                  >{{ $t("Cancel") }}</vs-button
                >
              </div>
            </vx-card>
            <div v-show="done == true" class="row">
              <div class="col-sm-12 col-md-10 col-lg-8 mx-auto">
  <div class="thankyou-wrap text-center">
    <h3 class="text-center p-3 mb-4 thankyou-heading">
      {{ $t("PasswordUpdated") }}
    </h3>
    <p class="text-muted text-center font-sm mb-4 thankyou-message">
      {{ $t("YourPasswordHasBeenUpdatedSuccessfully") }}
    </p>

    <div class="col-md-9 text-center mt-5">
      <router-link class="navbar-brand" to="/">
        <button
          type="submit"
          class="rounded-pill btn btn-primary btn-block btn-lg submit-btn"
          icon="fa fa-arrow-Left"
        >
          {{ $t("BackToHome") }}
        </button>
      </router-link>
    </div>
  </div>
</div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import moduleAuth from "@/store/auth/moduleAuth.js";
import axios from "@/axios.js";
export default {

  data() {
    return {
      done: false,
      Model: {
        old_password: "",
        new_password: ""
      },
      ConfirmPassword: ""
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.Model.old_password !== "" &&
        this.Model.new_password !== "" &&
        this.Model.new_password == this.ConfirmPassword
      );
    }
  },

  methods: {
    cancle() {
      this.$router.go(-1);
    },
    ChangePassword() {
      debugger;
      var Token={Authorization: 'Bearer ' + localStorage.accessToken};
      axios
        .post("/api/Auth/ChangePassword", this.Model,{headers:Token})
        .then(response => {

          console.log(response);
          this.done=response.data.data;

        })
      // this.$store
      //   .dispatch("auth/ChangePassword", this.Model)
      //   .then((r) => {
      //     debugger;
      //     console.log("change password",r);
      //     this.done = r.data.data;
      //   })
        .catch(() => {});
    }
  },
  created() {
    this.done = false;
    if (!moduleAuth.isRegistered) {
      this.$store.registerModule("auth", moduleAuth);
      moduleAuth.isRegistered = true;
    }

  }
};
</script>
<style>
  .thankyou-wrap {
    background-color: #f9fafb;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  .thankyou-heading {
    font-size: 2rem;
    font-weight: 700;
    color: #007bff;
  }

  .thankyou-message {
    font-size: 1.1rem;
    color: #6c757d;
    line-height: 1.6;
  }

  .submit-btn {
    padding: 12px 30px;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
    transition: all 0.3s ease;
  }

  .submit-btn:hover {
    background-color: #0056b3;
    box-shadow: 0 8px 15px rgba(0, 123, 255, 0.3);
  }

  .btn-block {
    display: inline-block;
    width: auto;
  }

  @media (max-width: 768px) {
    .thankyou-wrap {
      padding: 30px;
    }

    .thankyou-heading {
      font-size: 1.8rem;
    }

    .thankyou-message {
      font-size: 1rem;
    }

    .submit-btn {
      font-size: 1rem;
      padding: 10px 25px;
    }
  }
</style>
